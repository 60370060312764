import { Box } from "@mui/system";
import React, { useEffect, useState } from "react"
import { Backdrop, Button, Divider, Fade, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ITEM_PERFECT_INLINED } from "../constants/style";

const VmModal = ({
  openModal,
  onClose,
  title,
  children,
  buttonLabel = "Confirm Action",
  buttonLabelWithoutConfirm = "Action",
  onClickConfirmedButton = null,
  disabledCondition = false,
  width = 0,
  showButton = true,
  icon = <></>,
  buttonLoading = false,
}) => {
  const { t } = useTranslation();
  const [confirmButton, setConfirmButton] = useState<boolean>(false);

  const MODAL_STYLE = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > 0 ? width : 400,
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: 24,
  };

  useEffect(() => {
    setConfirmButton(false);
  }, [onClose])

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={MODAL_STYLE}>
          <div className="bg-theme text-white" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
            <Box sx={ITEM_PERFECT_INLINED} p={2} borderColor="lightgray" marginBottom={2}>
              {icon}
              <Typography fontWeight="bold" variant="h6">
                {title}
              </Typography>
            </Box>
          </div>
          <div className="p-4 pt-3">{children}</div>
          {
            showButton &&
            <div className="text-right p-4">
              {confirmButton ?
                <>
                  {/* @ts-ignore */}
                  <Button
                    variant="outlined"
                    disabled={disabledCondition || buttonLoading}
                    onClick={onClickConfirmedButton}
                    color="success"
                  >
                    {buttonLoading ? "Loading...." : buttonLabel}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{ marginLeft: 2 }}
                    color="error"
                  >
                    {t('CANCEL')}
                  </Button>
                </>
                :
                <Button
                  variant="outlined"
                  disabled={disabledCondition || buttonLoading}
                  onClick={() => setConfirmButton(true)}
                >
                  {buttonLoading ? "Loading...." : buttonLabelWithoutConfirm}
                </Button>}
            </div>
          }
        </Box>
      </Fade>
    </Modal>
  )
}

export default VmModal;
