import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { navigate } from "gatsby";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const GroupIndexPage = observer(() => {
  const { groupStore, rootStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetGroup, setTargetGroup] = useState<any>();
  const [name, setName] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.GROUP) == 0 ||
    groupStore.groupList.data.length > PAGE_SIZE) {
      groupStore.getGroupList(PAGE_SIZE, 0);
    }
  }, []);


  const onActionGroup = () => {
    let req: any = {
      id: 0,
      name,
      collegeId: GetCollegeId()
    }
    if (targetGroup) {
      req.id = targetGroup.id;
      groupStore.updateGroup(req).then(() => {
        groupStore.getGroupList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      groupStore.createGroup(req).then(() => {
        groupStore.getGroupList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = groupStore.groupList.data.find(g => g.id == id);
      setTargetGroup(target);
      setName(target.name);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetGroup(null);
    setName("");
  }

  const handleDeleteModal = (id: number) => {
    let target = groupStore.groupList.data.find(g => g.id == id);
    setTargetGroup(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('GROUP')}
      pageHeading={t('GROUP')}
      breadCrumb={[
        { label: t('GROUP') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/group" }
      ]}
      buttonLabel={t('ADD_A') + t('GROUP')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={groupStore.loading}
        page={page}
        paginationCount={groupStore.groupList.totalCount == 0 ? 1 : groupStore.groupList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          groupStore.getGroupList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('GROUP_NAME'), t('ACTION')]}
        tbody={groupStore.groupList.data.length > 0 &&
          groupStore.groupList.data.map((group, index) => (
            <>
              <Box marginY={1} />
              <tr key={`group_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{group.name}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(group.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate("/group/organisation", { state: { groupId: group.id } })}
                  >
                    {t('VIEW_ORG')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(group.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetGroup ? t('UPDATE_A') + t('GROUP') : t('ADD_A') + t('GROUP')}
        buttonLabel={targetGroup ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetGroup ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionGroup}
        buttonLoading={groupStore.loading}
        disabledCondition={name === ""}
      >
        <TextField
          value={name}
          label={t('GROUP_NAME')}
          variant="outlined"
          sx={{ width: '100%' }}
          onChange={(value) => setName(value.target.value)}
        />
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        buttonLoading={groupStore.loading}
        onClickConfirmedButton={() => groupStore.deleteGroup(targetGroup.id).then(() => {
          groupStore.getGroupList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
          handleCloseModal();
        }).catch(() => rootStore.notify('Delete failed, there are other records has linked to this company group', "error"))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}: Company Group?</Typography>
        {/* <Typography color="red" variant="caption">
          {t('DELETE_INFO_GROUP')}
        </Typography> */}
      </VmModal>
    </ContentLayout>
  )
});

export default GroupIndexPage;
